import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { HeroItem } from "@prmf/site-data";
import { routes } from "@prmf/site-router";
import { Link, useLoaderData } from "@remix-run/react";
import { clsx } from "clsx";
import BgImage from "./BgImage";
import InView from "./InView";
import Trustpilot from "./Trustpilot";

export default function HomeHero() {
  const { hero } = useLoaderData<{ hero: HeroItem }>();
  const isCaseStudy = hero.link.startsWith(routes.newsCaseStudies);
  return (
    <InView.Self threshold={0.5}>
      {({ inView }) => (
        <BgImage
          src={hero.image}
          className={clsx(
            "relative overflow-hidden",
            "grid aspect-square max-h-[80vh]",
            "content-center justify-start",
            "bg-gray-300",
          )}
        >
          <div
            className={clsx(
              "flex transition",
              inView ? "ease-out" : "-translate-x-full ease-in",
            )}
          >
            <div className="flex flex-col items-end">
              <h3
                className={clsx(
                  "relative z-10",
                  "grid h-12 content-center md:h-16",
                  "p-3 text-sm font-bold md:text-base",
                  "bg-accent whitespace-nowrap",
                )}
              >
                <span
                  className={clsx(
                    "block w-min max-w-[60vw] truncate",
                    "transition",
                    inView ? "delay-150" : "-translate-x-[110%]",
                  )}
                >
                  {hero.title}
                </span>
              </h3>
              <Link
                to={hero.link}
                className={clsx(
                  "flex items-center gap-2 p-2 text-xs",
                  "bg-black text-white transition",
                  "hover:bg-accent hover:text-black",
                  inView ? "delay-100" : "-translate-y-full",
                )}
              >
                <PlusCircleIcon className="h-5 w-5" />
                <span className="font-bold">
                  {isCaseStudy ? "View case study" : "View the range"}
                </span>
              </Link>
            </div>
            <BgImage
              src={hero.logo}
              className={clsx(
                "aspect-video h-12 bg-white md:h-16",
                "transition",
                inView ? "delay-100" : "-translate-x-full",
              )}
            />
          </div>
          <Trustpilot className="absolute bottom-0 right-10 hidden md:block" />
        </BgImage>
      )}
    </InView.Self>
  );
}
